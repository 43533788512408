<template>
	<div class="garden-list-page">
		<div class="search-box">
			<a-row class="search-row">
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">联系人：</a-col>
						<a-col :span="18" class="value-box">
							<a-input placeholder="请输入" v-model="name"/>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">联系电话：</a-col>
						<a-col :span="18" class="value-box">
							<a-input placeholder="请输入" v-model="phone"/>
						</a-col>
					</a-row>
				</a-col>
				<a-col class="btn-box">
					<a-button type="default" @click="resetTiao">重置</a-button>
					<a-button type="primary" @click="searchList">查询</a-button>
				</a-col>
			</a-row>
			
		</div>
		<div class="color-block"></div>
		<div class="list-table-box">
			<a-table :rowKey="(item,index) => {return index}" 
				:loading="dataLoading" 
				:columns="columns"
				:data-source="list"
				:pagination="{hideOnSinglePage: true, current: page.current, total: page.total}"
				@change="listChange"
				:bordered="true">
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-popconfirm title="确认删除该委托" ok-text="是" cancel-text="否" @confirm="deleteItem(item)">
							<a-button size="small" type="danger">删除</a-button>
						</a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			let columns = [{
				title: '园区名称',
				key: 'park_name',
				dataIndex: 'park_name'
			},{
				title: '联系人',
				key: 'res_name',
				dataIndex: 'res_name'
			},{
				title: '联系电话',
				key: 'res_phone',
				dataIndex: 'res_phone'
			},{
				title: '需求描述',
				key: 'res_describe',
				dataIndex: 'res_describe'
			},{
				title: '操作',
				dataIndex: 'operation',
				scopedSlots: { customRender: 'operation' },
				width: '140px'
			}]
			return {
				dataLoading: false,
				columns,
				list: [],
				name: '',
				phone: '',
				page:{
					current: 1,
					total: 0
				},
				showIng: false,
				reIng: false
			}
		},
		computed: {},
		created() {
			this.getReserveParkList()
		},
		mounted() {
			
		},
		methods:{
			searchList() {
				let t = this;
				t.page.current = 1
				t.getReserveParkList()
			},
			getReserveParkList() {
				let t = this
				let data ={
					company_name: t.company_name,
					name: t.name,
					phone: t.phone,
					page: t.page.current
				}
				t.$post('reserveParkList',data).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data.list
						t.page.total = data.total
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			deleteItem(item) {
				let t = this
				t.$post('reserveParkDelete',{res_house_id: item.res_house_id}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success('删除成功',1.5)
					    t.searchList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			listChange(e) {
				this.page.current = e;
				this.getParkList();
			},
			resetTiao() {
				this.park_type_id = undefined
				this.park_name = ''
				this.status = undefined
				// this.searchList()
			},
			toggleShow(id,is_show) {
				let t = this
				if(t.showIng) {
					return 
				}else{
					t.showIng = true
				}
				t.$post('parkFabu',{park_id: id}).then(res => {
					t.showIng = true
					let { code, data, msg } = res
					if (code == 0) {
						let str = is_show == 1 ? '已关闭' : '已启用'
						
						console.log(str)
 						t.$message.success(str,1.5)
					    t.getParkList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.showIng = true
				})
			},
			recoChange(id,is_show) {
				let t = this
				if(t.reIng) {
					return 
				}else{
					t.reIng = true
				}
				t.$post('parkRecommend',{park_id: id}).then(res => {
					t.reIng = false
					let { code, data, msg } = res
					if (code == 0) {
						let str = is_show == 1 ? '已关闭' : '已启用'
						
						console.log(str)
						t.$message.success(str,1.5)
					    t.getParkList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.reIng = false
				})
			}
 		}
	}
</script>

<style lang="less">
	.head-img-box {
		width: 100%;
		max-height: 120px;
		img{
			width: 100%;
			height: 100%;
			display: block;
			object-fit: contain;
		}
	}
	
	
</style>
